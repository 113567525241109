import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { IInventorySummary } from "../../interface/inventory-summary.interface";
import { Action, createReducer, on } from "@ngrx/store";
import * as InventorySummaryActions from "../action/inventory-summary.actions";
import { IAssignInventory } from "../../interface/assing-inventory.interface";

export interface InventorySummaryState extends EntityState<any> {
  inventorySummary: Array<IInventorySummary>;
  assignInventory: Array<IAssignInventory>;
  error: string;
}
export const defaultState: InventorySummaryState = {
  entities: {},
  ids: [],
  inventorySummary: [],
  assignInventory: [],
  error: "",
};
export const inventorySummaryAdapter: EntityAdapter<IInventorySummary> =
  createEntityAdapter<IInventorySummary>();

export const InventorySummaryInitialState =
  inventorySummaryAdapter.getInitialState(defaultState);

const reducer = createReducer(
  InventorySummaryInitialState,
  on(
    InventorySummaryActions.SuccessGetInventorySummary,
    (state, { inventorySummary }) => ({
      ...state,
      inventorySummary,
    })
  ),
  on(
    InventorySummaryActions.ErrorGetInventorySummary,
    (state, { payload }) => ({
      ...state,
      error: payload,
    })
  ),
  on(
    InventorySummaryActions.SuccessGetAssignInventory,
    (state, { assignInventory }) => ({
      ...state,
      assignInventory,
    })
  ),
  on(InventorySummaryActions.ErrorGetAssignInventory, (state, { payload }) => ({
    ...state,
    error: payload,
  }))
);

export function inventorySummaryReducer(
  state: EntityState<IInventorySummary> & InventorySummaryState,
  action: Action<string>
) {
  return reducer(state, action);
}
