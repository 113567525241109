import { createAction, props } from "@ngrx/store";
import { IAwbSummary } from "../../interface/awb-summary.interface";

export enum AwbSummaryActionsTypes {
    getAwbSummary = '[Awb Summary] get Awb Summary',
    successGetAwbSummary = '[Awb Summary] Success get Awb Summary',
    errorGetAwbSummary = '[Awb Summary] Error get Awb Summary',
}

export const GetAwbSummary = createAction(AwbSummaryActionsTypes.getAwbSummary, props<{ filters: string }>())
export const SuccessGetAwbSummary = createAction(AwbSummaryActionsTypes.successGetAwbSummary, props<{ awbSummary: Array<IAwbSummary> }>())
export const ErrorGetAwbSummary = createAction(AwbSummaryActionsTypes.errorGetAwbSummary, props<{ payload: any }>())