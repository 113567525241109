import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { IAwbSummary } from "../../interface/awb-summary.interface";
import { Action, createReducer, on } from "@ngrx/store";
import * as AwbSummaryActions from '../actions/awb-summary.actions'

export interface AwbSummaryState extends EntityState<any> {
  awbSummary: Array<IAwbSummary>;
  error: string;
}
export const defaultState: AwbSummaryState = {
  entities: {},
  ids: [],
  awbSummary: [],
  error: "",
};

export const awbSummaryAdapter: EntityAdapter<IAwbSummary> =
  createEntityAdapter<IAwbSummary>();

export const AwbSummaryInitialState =
  awbSummaryAdapter.getInitialState(defaultState);

const reducer = createReducer(
    AwbSummaryInitialState,
  on(
    AwbSummaryActions.SuccessGetAwbSummary,
    (state, { awbSummary }) => ({
      ...state,
      awbSummary,
    })
  ),
  on(
    AwbSummaryActions.ErrorGetAwbSummary,
    (state, { payload }) => ({
      ...state,
      error: payload,
    })
  ),
);

export function awbSummaryReducer(
  state: EntityState<IAwbSummary> & AwbSummaryState,
  action: Action<string>
) {
  return reducer(state, action);
}
