import { createAction, props } from "@ngrx/store";
import { IInventorySummary } from "../../interface/inventory-summary.interface";
import { IAssignInventory } from "../../interface/assing-inventory.interface";

export enum InventorySummaryActionsTypes {
    getInventorySummary = '[Inventory Summary] get Inventory Summary',
    successGetInventorySummary = '[Inventory Summary] Success get Inventory Summary',
    errorGetInventorySummary = '[Inventory Summary] Error get Inventory Summary',

    getAssignInventory = '[Assign Inventory] get Assign Inventory', 
    successAssignInventory = '[Assign Inventory] Success get Assign Inventory', 
    errorAssignInventory = '[Assign Inventory] Error get Assign Inventory', 
}

export const GetInventorySummary = createAction(InventorySummaryActionsTypes.getInventorySummary, props<{ filters: string }>())
export const SuccessGetInventorySummary = createAction(InventorySummaryActionsTypes.successGetInventorySummary, props<{ inventorySummary: Array<IInventorySummary> }>())
export const ErrorGetInventorySummary = createAction(InventorySummaryActionsTypes.errorGetInventorySummary, props<{ payload: any }>())

export const GetAssignInventory  = createAction(InventorySummaryActionsTypes.getAssignInventory, props<{ filters: string }>())
export const SuccessGetAssignInventory  = createAction(InventorySummaryActionsTypes.successAssignInventory, props<{ assignInventory: Array<IAssignInventory> }>())
export const ErrorGetAssignInventory  = createAction(InventorySummaryActionsTypes.errorAssignInventory, props<{ payload: any }>())